import { db } from 'dbs/clientDb';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { getCollectionData } from 'utils/firebaseUtils';

export async function createUser(authUser, isCouncilMember = false) {
  try {
    const docRef = doc(db, `users/${authUser.uid}`);
    await setDoc(docRef, { email: authUser.email });

    return { status: 'ok', data: { email: authUser.email, isCouncilMember } };
  } catch (error) {
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function getUserWorkshopRefs(authUser) {
  const uid = authUser?.uid;
  if (!uid) return;
  const col = collection(db, `users/${authUser.uid}/workshops`);

  return await getDocs(col).then(getCollectionData);
}

async function getCouncil({ uid }) {
  try {
    const docRef = collection(db, 'council');
    const snap = await getDocs(docRef);
    let isCouncilMember = false;
    snap.forEach((doc) => {
      if (isCouncilMember) return;
      isCouncilMember = doc.id === uid;
    });

    return isCouncilMember;
  } catch (error) {
    console.log('error @getCouncil', error);
    return null;
  }
}
export async function getOrCreateUser(authUser) {
  try {
    const docRef = doc(db, `users/${authUser.uid}`);
    const snap = await getDoc(docRef);
    const isCouncilMember = await getCouncil({ uid: authUser.uid });

    if (snap.exists()) return { status: 'ok', data: { ...snap.data(), isCouncilMember } };

    return await createUser(authUser);
  } catch (error) {
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function updateUser(user) {
  try {
    const docRef = doc(db, `users/${user.uid}`);

    try {
      await setDoc(docRef, {
        profileImageUrl: user?.profileImageUrl || '',
        email: user?.email,
        name: user?.name || '',
        title: user?.title || ''
      });
      return { status: 'ok' };
    } catch (error) {
      return { status: 'ko', error: error?.message };
    }
  } catch {
    return null;
  }
}
export async function getUserDetails(uid) {
  try {
    const docRef = doc(db, `users/${uid}`);
    const snap = await getDoc(docRef);

    return { status: 'ok', data: snap.data() };
  } catch (error) {
    console.log('error @getUserDetails', error);
    return { status: 'ko' };
  }
}
export async function getUserByEmail(email) {
  try {
    // const { data } = await API.graphql({
    //   query: listUsersQuery,
    //   variables: { filter: { email: { eq: email } } },
    //   authMode: 'AMAZON_COGNITO_USER_POOLS'
    // });
    // return data?.listUsers?.items?.[0];
  } catch (error) {
    console.log('getUserByEmail error', error);
    return null;
  }
}
