export function getDocumentIds(snap) {
  const ids = [];
  snap.forEach((doc) => ids.push(doc.id));
  return ids;
}

export function getCollectionData(snap) {
  const data = [];
  snap.forEach((doc) => {
    data.push({
      ...doc.data(),
      id: doc.id
    });
  });
  return data;
}
