import produce from 'immer';

export const immer = (config) => (set, get, api) => config((fn) => set(produce(fn)), get, api);

export const log = (config) => (set, get, api) =>
  config(
    (args) => {
      // console.log('  applying', args);
      set(args);
      // console.log('  new state', get());
    },
    get,
    api
  );
