import { USER_ROLES } from 'constants/enums';
import { useEffect, useRef } from 'react';
import * as userService from 'services/userService';
import useSWR from 'swr';
import { immer } from 'utils/immer';
import create from 'zustand';

import useAuth from './useAuth';

export const useUserStore = create(
  immer((set) => ({
    user: USER_ROLES.Initializing,
    setUser: (user) =>
      set((state) => {
        state.user = user;
      })
  }))
);

export default function useUser() {
  const { authUser } = useAuth();
  const { user, setUser } = useUserStore((state) => ({
    user: state.user,
    setUser: state.setUser
  }));
  const initializing = authUser === USER_ROLES.Initializing;
  const { data: userData } = useSWR(initializing || user?.uid ? null : 'user', () =>
    getOrCreateUser(authUser)
  );

  useEffect(async () => {
    if (initializing) return;
    else if (!authUser?.uid) setUser(null);
    else if (userData?.status === 'ok') setUser({ uid: authUser.uid, ...userData?.data });
    else return;
  }, [authUser, userData]);

  async function getOrCreateUser(authUser) {
    const newUser = await userService.getOrCreateUser(authUser);
    console.log('newUser', newUser);
    return newUser;
  }

  async function checkAndCreateUser(user) {
    const dbUser = await userService.getUserByEmail(user.email);

    if (dbUser?.uid) return dbUser;
    else if (dbUser?.email) await userService.updateUser(user);
    else return await userService.createUser(user);
  }

  async function updateUser(user) {
    const result = await userService.updateUser(user);

    if (result.status === 'ok') {
      setUser(user);
    }
  }

  async function getUserDetails(uid) {
    console.log('getUserDetails uid', uid);
    const result = await userService.getUserDetails(uid);
    console.log('getUserDetails result', result);
    return result?.data;
  }

  return { user, checkAndCreateUser, updateUser, getUserDetails };
}
